import './medicationcard.css'
import React, { useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Form, Select } from 'antd';
import { AddIcon, CloseIcon, SaveIcon } from '../../assets/Logos/Icons';
import CommonDeleteModalBody from '../../components/CommonDeleteModalBody/CommonDeleteModalBody'
import { UPDATE_CONSULTATION_ADD_MEDICATION, UPDATE_CONSULTATION_EDIT_MEDICATION, UPDATE_CONSULTATION_REMOVE_MEDICATION } from '../../actions/type';


function MedicationCard({ addMedication, medications, setForm }) {

    const { Option } = Select;
    let [isSuccess, setSuccess] = useState(false)
    let [deleteelement, setDeleteelement] = useState(false)
    const [deleteModal, setDeleteModal] = useState(false)
    const [deleteIndex, setDeleteIndex] = useState(null)
    let dispatch = useDispatch()

    const [form] = Form.useForm();
    let [forms, setForms] = useState([
        {
            id:medications&&medications.length > 0 ? medications.at(-1).id + 1 : 1,
            name: "",
            duration: "",
            frequency: "",
            status: ""
        }
    ])

    

    let addElement = async () => {

        // form.validateFields().then((res)=>{
        //     console.log(res);

        //     if(res)
        //     {
        //         setForms([
        //             ...forms,
        //             {
        //                 id: forms.at(-1).id + 1,
        //                 name: "",
        //                 duration: "",
        //                 status: ""
        //             }
        //         ])
        //     }
        // })


        try {
            await form.validateFields();

            console.log("Validation is successful");
            setForms([
                ...forms,
                {
                    id: forms.at(-1).id + 1,
                    name: "",
                    duration: "",
                    status: "",
                    frequency: ""
                }
            ])
            // Validation is successful
        } catch (errors) {
            console.log("Errors in the fields");
            // Errors in the fields
        }





    }

    let removeElement = (index) => {

         setDeleteModal(true)
        setDeleteIndex(index)

    }


    let updateCard = ({ key, value, id }) => {

        let updatedForm = forms.map((each) => {
            if (each.id == id) {
                each[key] = value
            }
        })
        setForm(updatedForm)

    }


    let handleubmitForm = (data) => {

        addMedication(forms)
        setForm(false)
    }

    const handlePopupDeleteOk = () => {

        dispatch({
            type: UPDATE_CONSULTATION_REMOVE_MEDICATION,
            payload: { index:deleteIndex }
        })

        // setDeleteIndex(null)
        setForm(false)

    }


    return (
        <div className="medication-form-container">
            <Form
                form={form}
                name="basic"
                onFinish={handleubmitForm}
            // onFinishFailed={onFinishFailed}
            >



                {
                    forms && forms.length > 0 ?

                        forms.map((element, key) => {
                            console.log(key)
                            return (



                                <div className="medication-card-container">

                                    <Form.Item
                                        name={`name${key}`}
                                        rules={[{ required: true, message: 'Enter name of medicine ' }]}
                                    >


                                        <input
                                            onChange={(e) => { updateCard({ key: "name", value: e.target.value, id: element.id }) }}
                                            key={element.id} type="text"
                                            className={`medication-card-input ${form.getFieldError(`'name${key}'`) ? true : false}`} 
                                            placeholder='Enter Medicine Name'/>


                                    </Form.Item>
                                    <Form.Item
                                            name={`frequency${key}`}
                                            rules={[{ required: true, message: 'Enter how often do you take/use the medicine' }]}
                                        >
                                            <Select
                                                onChange={(e) => { updateCard({ key: "frequency", value: e, id: element.id }) }}
                                                placeholder="Select Frequency" style={{ width: "100%", fontSize: "14px", height: "100%", fontWeight: 500 }}>
                                               <Option  value="30 Minutes Before Breakfast">30 Minutes Before Breakfast</Option>
                                                <Option  vvalue="30 Minutes Before Dinner">30 Minutes Before Dinner</Option>
                                                <Option  value="30 Minutes Before Food">30 Minutes Before Food</Option>
                                                <Option  value="30 Minutes Before Lunch">30 Minutes Before Lunch</Option>
                                                <Option  value="6 hr for fever > 100 f">6 hr for Fever > 100 f</Option>
                                                <Option  value="After Dinner">After Dinner</Option>
                                                <Option  value="After Food">After Food</Option>
                                                <Option  value="After Lunch">After Lunch</Option>
                                                <Option  value="As Directed">As Directed</Option>
                                                <Option  value="Before Food">Before Food</Option>
                                                <Option  value="Every fourth hourly">Every Fourth Hourly</Option>
                                                <Option  value="For immediate use">For Immediate Use</Option>
                                                <Option  value="Four Times Daily">Four Times Daily</Option>
                                                <Option  value="Half a Day-Evening">Half a Day - Evening</Option>
                                                <Option  value="Half a Day-Evening and Noon">Half a Day - Evening and Noon</Option>
                                                <Option  value="Half a Day-Morning">Half a Day - Morning</Option>
                                                <Option  value="Half a Day-Morning and Evening">Half a Day - Morning and Evening</Option>
                                                <Option  value="Half a Day-Morning and Noon">Half a Day - Morning and Noon</Option>
                                                <Option  value="Half a Day-Noon">Half a Day - Noon</Option>
                                                <Option  value="Half tablet, thrice daily">Half Tablet, Thrice Daily</Option>
                                                <Option  value="Hourly">Hourly</Option>
                                                <Option  value="On Alternate Days">On Alternate Days</Option>
                                                <Option  value="On Empty Stomach">On Empty Stomach</Option>
                                                <Option  value="Once Daily - Evening">Once Daily - Evening</Option>
                                                <Option  value="Once Daily - Morning">Once Daily - Morning</Option>
                                                <Option  value="Once Daily - Morning and Evening">Once Daily - Morning and Evening</Option>
                                                <Option  value="Once Daily - Night">Once Daily - Night</Option>
                                                <Option  value="Once Daily - Noon">Once Daily - Noon</Option>
                                                <Option  value="Once a Month">Once a Month</Option>
                                                <Option  value="Once a Week">Once a Week</Option>
                                                <Option  value="SOS">SOS</Option>
                                                <Option  value="Thrice a Day">Thrice a Day</Option>
                                                <Option  value="Thrice Daily">Thrice Daily</Option>
                                                <Option  value="Thrice Weekly">Thrice Weekly</Option>
                                                <Option  value="Twice a Day - Noon and Night">Twice a Day - Noon and Night</Option>
                                                <Option  value="Twice Daily">Twice Daily</Option>
                                                <Option  value="Twice Daily - Morning and Evening">Twice Daily - Morning and Evening</Option>
                                                <Option  value="Twice Daily - Morning and Night">Twice Daily - Morning and Night</Option>
                                                <Option  value="Twice Daily - Morning and Noon">Twice Daily - Morning and Noon</Option>
                                                <Option  value="Twice Daily - Noon and Evening">Twice Daily - Noon and Evening</Option>
                                                <Option  value="Twice Weekly">Twice Weekly</Option>
                                                <Option  value="Use when required">Use When Required</Option>
                                                <Option  value="With First Bite of Food">With First Bite of Food</Option>

                                            </Select>
                                        </Form.Item>
                                    
                                        <Form.Item
                                            name={`duration${key}`}
                                            rules={[{ required: true, message: 'Enter how much of the medicine you take/use' }]}
                                        >
                                            <input
                                                onChange={(e) => { updateCard({ key: "duration", value: e.target.value, id: element.id }) }}
                                                type="number" className="medication-card-input-50 w-100" placeholder='Enter Dosage'/>
                                        </Form.Item>


                                       

                                        <Form.Item
                                            name={`status${key}`}
                                            rules={[{ required: true, message: 'Do you still take/use the medicine' }]}
                                        >
                                            <Select
                                                onChange={(e) => { updateCard({ key: "status", value: e, id: element.id }) }}
                                                placeholder="Current Status" style={{ width: "100%", fontSize: "14px", height: "100%", fontWeight: 500 }}>
                                                <Option value="Take this">Take this</Option>
                                                <Option value="No longer take this">No longer take this</Option>

                                            </Select>
                                        </Form.Item>
                                    

                                    {/* <Select value="After Lunch" style={{ width: "100%", fontSize: "14px", marginTop: ".8rem" }}>
                                        <Option value="After Lunch">After Lunch</Option>
                                    </Select> */}

                                    <div onClick={() => { removeElement(element.id) }} className="d-flex flex-row justify-content-start align-items-center" style={{ marginTop: ".8rem", cursor: "pointer" }}>
                                    <img src={require('../../assets/InterBranch/PNGIcons/delete.png')} alt="" style={{width:"25px"}} />
                                        <span className="remove-text">Remove</span>
                                    </div>




                                </div>

                            )
                        })


                        : null
                }




                <div className='add-itrms-button-container'>
                    <button
                        type='button'
                        className="medicines-list-add-btn secondary-add-button "
                        onClick={addElement}
                    ><AddIcon primary /> Add more</button>
                    <button
                        type='submit'
                        className="medicines-list-add-btn "
                    // onClick={saveElements}
                    >
                        <SaveIcon/>
                        Save</button>
                </div>



                {/* <button className="medicines-list-add-btn w-100"> Save</button> */}

            </Form>
            {/* {isSuccess  &&
                <CustomeModal
                                closeModal={removeElement()}
                                state={isSuccess}
                                modalName="deleteMedication"
                            
                            />
            } */}
            <CommonDeleteModalBody
                onOk={handlePopupDeleteOk}
                className="consultation-delete-modal"
                state={deleteModal}
                setState={setDeleteModal} />
        </div>
    )
}

export default MedicationCard
