import { ADD_DEFAULT_PRE_EXISTING, CHECK_CONSULTATION, UPDATE_CONSULTATION_ADD_ALLERGY, UPDATE_CONSULTATION_ADD_MEDICATION, UPDATE_CONSULTATION_ADD_PRESCRIPTION, UPDATE_CONSULTATION_ADD_TEST, UPDATE_CONSULTATION_EDIT_ALLERGY, UPDATE_CONSULTATION_EDIT_MEDICATION, UPDATE_CONSULTATION_EDIT_PRESCRIPTION, UPDATE_CONSULTATION_EDIT_TEST, UPDATE_CONSULTATION_LIFESTYLE, UPDATE_CONSULTATION_NOTE, UPDATE_CONSULTATION_PRE_EXISTING_CONDITION, UPDATE_CONSULTATION_REMOVE_ALLERGY, UPDATE_CONSULTATION_REMOVE_MEDICATION, UPDATE_CONSULTATION_REMOVE_PRESCRIPTION, UPDATE_CONSULTATION_REMOVE_TEST } from "../actions/type";

let defaultPreExistingValue = {
  status: "",
  family: ""
}

const INITIAL_STATE = {
  lifestyle: {
    HighBloodPressure: "",
    ConsumeAlcohol: "",
    SmokeUseTobacco: "",
    Exercise: "",
    TakeMedication: "",
    GetSleep: "",
    HaveChroniccondition: "",
    OverseasTravel: ""
  },
  preExistingConditions: {
    anemia: defaultPreExistingValue,
    asthma: defaultPreExistingValue,
    diabetes: defaultPreExistingValue,
    cholesterol: defaultPreExistingValue,
    thyroidDisease: defaultPreExistingValue,
    heartDisease: defaultPreExistingValue,
    stroke: defaultPreExistingValue,
    glaucoma: defaultPreExistingValue
  },
  currentMedication: [],
  allergy: [],
  test: [],
  note: {
    CC: "",
    RP: "",
    DP: "",
    NP: "",
    DPN: "",
    NN:""

  },
  prescription: []
};

export default (state = INITIAL_STATE, { type, payload }) => {




  switch (type) {
    case CHECK_CONSULTATION:
      return payload;

    case UPDATE_CONSULTATION_LIFESTYLE:
      let _lifestyle = { ...state.lifestyle }
      _lifestyle[payload.key] = payload.value
      return { ...state, lifestyle: _lifestyle };

    case UPDATE_CONSULTATION_PRE_EXISTING_CONDITION:
      let _preexisting = { ...state.preExistingConditions }
      _preexisting[payload.key][payload.owner] = payload.value
      return { ...state, preExistingConditions:_preexisting  };

    case UPDATE_CONSULTATION_NOTE:

      return { ...state, note: { ...state.note, [payload.key]: payload.value } };


    case UPDATE_CONSULTATION_ADD_MEDICATION:
      return { ...state, currentMedication: [...state.currentMedication, ...payload.data] };

    case UPDATE_CONSULTATION_EDIT_MEDICATION:

      let _medications = [...state.currentMedication]
      _medications.map((each_med__for_edit) => {

        if (each_med__for_edit.id == payload.id) {

          each_med__for_edit.name = payload.data.name
          each_med__for_edit.duration = payload.data.duration
          each_med__for_edit.status = payload.data.status
        }

      })
      return { ...state, currentMedication: _medications };

    case UPDATE_CONSULTATION_REMOVE_MEDICATION:

      let _medicationsForRemove = [...state.currentMedication]
      let removedMedications = _medicationsForRemove.filter(eachForRemove => eachForRemove.id != payload.index)
      return { ...state, currentMedication: removedMedications };


    case UPDATE_CONSULTATION_ADD_ALLERGY:
      return { ...state, allergy: [...state.allergy, ...payload.data] };

    case UPDATE_CONSULTATION_EDIT_ALLERGY:

      let _allergy = [...state.allergy]
      _allergy.map((each_allergy__for_edit) => {

        if (each_allergy__for_edit.id == payload.id) {

          each_allergy__for_edit.name = payload.data.name
          each_allergy__for_edit.reaction = payload.data.reaction
          each_allergy__for_edit.status = payload.data.status
        }

      })
      return { ...state, currentMedication: _allergy };

    case UPDATE_CONSULTATION_REMOVE_ALLERGY:

      let _allergyForRemove = [...state.allergy]
      let removeallergy = _allergyForRemove.filter(eachForRemoveAllergy => eachForRemoveAllergy.id != payload.index)

      console.log(removeallergy);
      return { ...state, allergy: removeallergy };


    case UPDATE_CONSULTATION_ADD_TEST:
      return { ...state, test: [...state.test, ...payload.data] };
    case UPDATE_CONSULTATION_EDIT_TEST:

      let _test = [...state.test]
      _test.map((each_test__for_edit) => {

        if (each_test__for_edit.id == payload.id) {

          each_test__for_edit.type = payload.data.type
          each_test__for_edit.summary = payload.data.summary
          each_test__for_edit.comment = payload.data.comment
        }

      })
      return { ...state, currentMedication: _test };
    case UPDATE_CONSULTATION_REMOVE_TEST:

      let _testForRemove = [...state.test]
      let removeTest = _testForRemove.filter(eachForRemoveTest => eachForRemoveTest.id != payload.index)
      return { ...state, test: removeTest };


    case UPDATE_CONSULTATION_ADD_PRESCRIPTION:
      return { ...state, prescription: [...state.prescription, ...payload.data] };
    case UPDATE_CONSULTATION_EDIT_PRESCRIPTION:
      let _prescription = [...state.test]
      _prescription.map((each_pres__for_edit) => {

        if (each_pres__for_edit.id == payload.id) {

          each_pres__for_edit = {
            ...each_pres__for_edit,
            ...payload.data
            // med_type: "Option 1",
            // med_name: "wew",
            // med_brand_sub: "",
            // med_when: "Option 1",
            // med_freequancy: "Option 1",
            // med_quantity: "32",
            // med_unit: true,
            // med_course_count: "23",
            // med_course_type: "Days",
            // med_instructions: "dsd",
          }
        }

      })
      return { ...state, prescription: _prescription };
    case UPDATE_CONSULTATION_REMOVE_PRESCRIPTION:

      console.log("payload.index ",payload.index)
      let _prescriptionForRemove = [...state.prescription]
      console.log("_prescriptionForRemove ",_prescriptionForRemove)
      let removePrescription = _prescriptionForRemove.filter(eachForRemovePrescription => eachForRemovePrescription.id != payload.index)
      return { ...state, prescription: removePrescription };

    case ADD_DEFAULT_PRE_EXISTING:

      return { ...state, preExistingConditions: payload.data };
    default:
      return state;
  }

};
