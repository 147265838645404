import React, { useState } from 'react'
import moment from 'moment'
import { useDispatch, useSelector } from 'react-redux';
import { UPDATE_CONSULTATION_ADD_PRESCRIPTION, UPDATE_CONSULTATION_EDIT_MEDICATION, UPDATE_CONSULTATION_REMOVE_PRESCRIPTION } from '../../actions/type';
import { AddIcon, ArrowLeft, CloseIcon, SaveIcon } from '../../assets/Logos/Icons'
import './prescribemedicine.css'
import { Form, Input, Select,DatePicker } from 'antd';
import { Checkbox } from 'antd';
import { convertDateToString } from '../../Helpers/dateFunctions';
import CommonDeleteModalBody from '../../components/CommonDeleteModalBody/CommonDeleteModalBody';

function PrescribeMedicine({ addData, data, setForm }) {

    const { Option } = Select;
    const { TextArea } = Input;
    let dispatch = useDispatch()
    let dummyOptions = ["Option 1", "Option 2", "Option 3"]
    let frequecyoption=['30 Minutes Before Breakfast','30 Minutes Before Dinner','30 Minutes Before Food','30 Minutes Before Lunch','6 hr for fever > 100 f','After Dinner','After Food','After Lunch','As Directed','Before Food','Every fourth hourly','For immediate use','Four Times Daily','Half a Day-Evening','Half a Day-Evening and Noon','Half a Day-Morning','Half a Day-Morning and Evening','Half a Day-Morning and Noon','Half a Day-Noon','Half tablet, thrice daily','Hourly','On Alternate Days','On Empty Stomach','Once Daily - Evening','Once Daily - Morning','Once Daily - Morning and Evening ','Once Daily - Night','Once Daily - Noon','Once a Month ','Once a Week','SOS','Thrice a Day','Thrice Daily','Thrice Weekly','Twice a Day - Noon and Night','Twice Daily','Twice Daily - Morning and Evening','Twice Daily - Morning and Night','Twice Daily - Morning and Noon','Twice Daily - Noon and Evening','Twice Weekly','Use when required','With First Bite of Food']
    let Medicinetypelist =["BOTTLE","CAPSULE","OINTMENT","SYRUP","TABLET","AMPOULE","CREAM","DROPS","ENEMA","FABRICATION","GARGLE","GEL","GENERAL","INHALERS","LOTION","ORAL FILM","PASTE","PATCHES","PESSARIES  ","POWDER","PREFILLED SYRINGE","RESPULES","SOLUTION","SPRAYS & INHALERS","SUPPOSITORIES","SURGICAL CONSUMABLES","SUSPENSION","SYRINGES & NEEDLES","VIAL"]
    let unitlist =["GRAMS","MCG","MG","MG\ML","ML","μm","NUM","Percentage"]
    const [form] = Form.useForm();
    const [deleteModal, setDeleteModal] = useState(false)
    const [deleteIndex, setDeleteIndex] = useState(null)
  
    let [forms, setForms] = useState([
        {
            id: data.length > 0 ? data.at(-1).id + 1 : 1,
            med_type: "",
            med_name: "",
            med_brand_sub: "",
            med_when: "",
            med_freequancy: "",
            med_startdate: "",
            med_quantity: "",
            med_unit: "",
            med_course_count: "",
            med_course_type: "",
            med_instructions: "",
        }
    ])


    let addElement = async () => {

        try {
            await form.validateFields();

            console.log("Validation is successful");
            setForms([
                ...forms,
                {
                    id: forms.at(-1).id + 1,
                    med_type: "",
                    med_name: "",
                    med_brand_sub: "",
                    med_when: "",
                    med_freequancy: "",
                    med_quantity: "",
                    med_unit: "",
                    med_startdate: "",
                    med_course_count: "",
                    med_course_type: "",
                    med_instructions: "",
                }
            ])
            // Validation is successful
        } catch (errors) {
            console.log("Errors in the fields");
            // Errors in the fields
        }


    }

    let removeElement = (index) => {

        // if (forms.length > 1) {
        //     setForms(forms.filter((element) => element.id != index))
        // }
    setDeleteIndex(index)
    setDeleteModal(true)

    }


    let updateCard = ({ key, value, id }) => {

        let updatedForm = forms.map((each) => {
            if (each.id == id) {
                each[key] = value
            }
        })

        setForm(updatedForm)

    }


    let handleubmitForm = (data) => {

        addData(forms)
        setForm(false)
    }
    function allowOnlyNumericsOrDigits(e) {
        if (e.target.value.includes(".") && e.key == ".") {
            e.preventDefault()
        }

        !["0", "1", "2", "3", "4", "5", "6", "7", "8", "9", ".", "Backspace"].includes(e.key) && e.preventDefault()

    }
    const handlePopupDeleteOk = () => {


        dispatch({
          type: UPDATE_CONSULTATION_REMOVE_PRESCRIPTION,
          payload: { index: deleteIndex }
        })
        setForm(false)
    
      }

console.log(forms)
    return (

        <div className="medication-form-container">
            <Form
                form={form}
                name="basic"
                onFinish={handleubmitForm}
                className="w-100"
            // onFinishFailed={onFinishFailed}
            >



                {
                    forms && forms.length > 0 ?

                        forms.map((element, key) => {

                            return (



                                <div className="medication-card-container">

                                    <div className='prescriptio-section-header'>Medicine Details</div>
                                    <Form.Item
                                        name={`med_type${key}`}
                                        //rules={[{ required: true, message: 'Select medicine type' }]}
                                    >

                                        <Select
                                            onChange={(e) => { updateCard({ key: "med_type", value: e, id: element.id }) }}
                                            placeholder="Medicine type" style={{ width: "100%", fontSize: "14px", height: "100%", fontWeight: 500 }}>
                                            {
                                                Medicinetypelist.map((item) => <Option value={item}>{item}</Option>)
                                            }

                                        </Select>

                                    </Form.Item>

                                    <Form.Item
                                        name={`med_name${key}`}
                                        rules={[{ required: true, message: 'Enter medicine name' }]}
                                    >
                                        <input
                                            onChange={(e) => { updateCard({ key: "med_name", value: e.target.value, id: element.id }) }}
                                            key={element.id}
                                            type="text"
                                            placeholder='Medicine Name'
                                            className={`medication-card-input ${form.getFieldError(`'med_name${key}'`) ? true : false}`}>
                                        </input>
                                    </Form.Item>


                                    <Form.Item
                                        style={{ marginBottom: "10px" }}
                                        name={`med_brand_sub${key}`}
                                    >
                                        <Checkbox  onChange={(e) => { updateCard({ key: "med_brand_sub", value: e.target.checked, id: element.id }) }}>Permit Brand substitution</Checkbox>

                                    </Form.Item>

                                    <div className='prescriptio-section-header'>Dosage</div>

                                    <Form.Item
                                        name={`med_when${key}`}
                                       // rules={[{ required: true, message: 'Select when ' }]}
                                    >


                                        <Select
                                            onChange={(e) => { updateCard({ key: "med_when", value: e, id: element.id }) }}
                                            placeholder="When to consume" style={{ width: "100%", fontSize: "14px", height: "100%", fontWeight: 500 }}>
                                            <Option value={"Before Food"}>Before Food</Option>
                                            <Option value={"After Food"}>After Food</Option>
                                            <Option value={"SOS"}>SOS</Option>
                                            
                                            

                                        </Select>

                                    </Form.Item>

                                    <Form.Item
                                        name={`med_freequancy${key}`}
                                        //rules={[{ required: true, message: 'Select frequency' }]}
                                    >


                                        <Select
                                            onChange={(e) => { updateCard({ key: "med_freequancy", value: e, id: element.id }) }}
                                            placeholder="Frequency" style={{ width: "100%", fontSize: "14px", height: "100%", fontWeight: 500 }}>
                                            {
                                                frequecyoption.map((item) => <Option value={item}>{item}</Option>)
                                            }

                                        </Select>

                                    </Form.Item>

                                    <div className="medication-cardduration-status-wrap" style={{ marginTop: ".8rem" }}>
                                        <Form.Item
                                            name={`med_quantity${key}`}
                                           // rules={[{ required: true, message: 'Enter quantity' }]}
                                        >
                                            <input
                                                onChange={(e) => { updateCard({ key: "med_quantity", value: e.target.value, id: element.id }) }}
                                                type="number"
                                                placeholder='Quantity'
                                                className="medication-card-input-50 w-100" onKeyDown={allowOnlyNumericsOrDigits}/>
                                        </Form.Item>

                                        <Form.Item
                                            name={`med_unit${key}`}
                                          //  rules={[{ required: true, message: 'Select unit' }]}
                                        >
                                            <Select
                                                onChange={(e) => { updateCard({ key: "med_unit", value: e, id: element.id }) }}
                                                placeholder="Unit" style={{ width: "100%", fontSize: "14px", height: "100%", fontWeight: 500 }}>
                                               {
                                                unitlist.map((item) => <Option value={item}>{item}</Option>)
                                                 }


                                            </Select>
                                        </Form.Item>
                                    </div>


                                    <div className="medication-cardduration-status-wrap" style={{ marginTop: ".0rem" }}>
                                        <Form.Item
                                            name={`med_course_count${key}`}
                                           // rules={[{ required: true, message: 'Enter count' }]}
                                        >
                                            <input
                                                onChange={(e) => { updateCard({ key: "med_course_count", value: e.target.value, id: element.id }) }}
                                                type="text"
                                                placeholder='Count'
                                                className="medication-card-input-50 w-100" />
                                        </Form.Item>

                                        <Form.Item
                                            name={`med_course_type${key}`}
                                            //rules={[{ required: true, message: 'Select type' }]}
                                        >
                                            <Select
                                                onChange={(e) => { updateCard({ key: "med_course_type", value: e, id: element.id }) }}
                                                placeholder="Select type"
                                                style={{ width: "100%", fontSize: "14px", height: "100%", fontWeight: 500 }}>
                                                <Option value={"Days"}>Days</Option>
                                                {/* <Option value={"Month"}>Month</Option> */}

                                            </Select>
                                        </Form.Item>
                                    </div>

                                    
                                        <Form.Item
                                            name={`med_startdate${key}`}
                                          //  rules={[{ required: true, message: 'Select Start date' }]}
                                        >
                                              <DatePicker
                                        
                                        className='input'
                                        style={{ width: "100% !important" }}
                                        format="DD-MMM-YYYY"
                                        placeholder="Date"
                                        disabledDate={(current) => {
                                            return current < moment().subtract(1, 'day');
                                        }}
                                        // onSelect={getTimeSlots}
                                        onChange={(e) => { 
                                            
                                           console.log() 
                                            updateCard({ key: "med_startdate", value: convertDateToString(e), id: element.id })
                                         }}
                                    // defaultValue={moment(new Date())} 
                                    />
                                            {/* <input
                                                onChange={(e) => { updateCard({ key: "med_startdate", value: e.target.value, id: element.id }) }}
                                                type="date"
                                                placeholder='Select Start date'
                                                className="medication-card-input-50 w-100" /> */}
                                        </Form.Item>

                                   


                                   <div className='prescriptio-section-header'>Special instructions</div> 
                                    <Form.Item
                                        name={`med_instructions${key}`}
                                       // rules={[{ required: false, message: '' }]}
                                    >
                                        <TextArea
                                            onChange={(e) => { updateCard({ key: "med_instructions", value: e.target.value, id: element.id }) }}
                                            type="text"
                                            className="medication-card-input-50 w-100" placeholder='Type here'/>
                                    </Form.Item>

                                    <div onClick={() => { removeElement(element.id) }} className="d-flex flex-row justify-content-start align-items-center" style={{ marginTop: ".8rem", cursor: "pointer" }}>
                                      <img src={require('../../assets/InterBranch/PNGIcons/delete.png')} alt="" style={{width:"25px"}} />
                                        <span className="remove-text">Remove</span>
                                    </div>




                                </div>

                            )
                        })


                        : null
                }


                <div className='add-itrms-button-container'>
                    <button
                        type='button'
                        className="medicines-list-add-btn secondary-add-button "
                        onClick={addElement}
                    ><AddIcon primary /> Add more</button>
                    <button
                        type='submit'
                        className="medicines-list-add-btn "
                    // onClick={saveElements}
                    >
                        <SaveIcon />
                        Save</button>
                </div>



                {/* <button className="medicines-list-add-btn w-100"> Save</button> */}

            </Form>
            
      <CommonDeleteModalBody
        onOk={handlePopupDeleteOk}
        className="consultation-delete-modal"
        state={deleteModal}
        setState={setDeleteModal} />
        </div>
    )
}

export default PrescribeMedicine
