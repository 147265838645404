/* eslint-disable import/no-anonymous-default-export */
import { 
    RPM_ADD_PATIENT_ALLERGIE, 
    RPM_ADD_PATIENT_MEDICATION,
    ESCALATE_TO_HCP,
    INSTRUCT_NURSE,
    SEND_AMBULANCE,
    FETCH_PATIENT_PROFILE,
    FETCH_PATIENTS_CONSULTATION_HISTORY,
    FETCH_PATIENTS_CONSULTATION_DETAILS,
    FETCH_SELECTED_APPOINTMENT_DETAILS,
    FETCH_PATIENTS_LIST,
    CLEAR_RPM_PATIENT_VITALS,
    CLEAR_RPM_ADMIN,FETCH_PATIENTS_VITALS,FETCH_PATIENTS_VITALS_SETTINGS,FETCH_PATIENTS_VITALS_HISTORY,FETCH_PATIENTS_HEALTH_DATA ,FETCH_PATIENTS_SINGLE_VITALS} from "../actions/type";


const INITIAL_STATE = {
    allergies: [],
    medications: [],

};
const INITIAL_STATE_VITALS = {};

export default (state = INITIAL_STATE, { type, payload }) => {
    switch (type) {

        case RPM_ADD_PATIENT_MEDICATION:
            return { ...state, medications: [...state.medications,payload] };
        case RPM_ADD_PATIENT_ALLERGIE:
            return { ...state, allergies: [...state.allergies,payload] };
        case ESCALATE_TO_HCP:
            return {
                ...state,
                escalateToHCP:payload
            };
        case INSTRUCT_NURSE:
            return {
                ...state,
                    instructNurse:payload
            };
        case SEND_AMBULANCE:
            return {
                ...state,
                    sendAmbulance:payload
            };
       case FETCH_PATIENT_PROFILE:
            return {
                ...state,
                    fetchPatientProfile:payload
            };
        case FETCH_PATIENTS_VITALS:
            return {
                ...state,
                    vitalslist:payload
            };
        case FETCH_PATIENTS_VITALS_SETTINGS:
                return {
                    ...state,
                        vitalsettings:payload
                };
        case FETCH_PATIENTS_VITALS_HISTORY:
            return {
                ...state,
                    vitalhistory:payload
            };
        case FETCH_PATIENTS_HEALTH_DATA:
            return {
                ...state,
                    patienthealthdata:payload
            };
        case FETCH_PATIENTS_SINGLE_VITALS:
                return {
                    ...state,
                        patientsvital:payload
                };
        case FETCH_PATIENTS_CONSULTATION_HISTORY:
                    return {
                        ...state,
                            consultationhistory:payload
                    };
        case FETCH_PATIENTS_CONSULTATION_DETAILS:
            return {
                ...state,
                    consultationdetails:payload
            };
        case FETCH_PATIENTS_LIST:
            return {
                ...state,
                    patients:payload
            };
        case FETCH_SELECTED_APPOINTMENT_DETAILS:
            return {
                ...state,
                    selectappointmentDetails:payload
            };
         //FETCH_SELECTED_APPOINTMENT_DETAILS
         //FETCH_PATIENTS_CONSULTATION_HISTORY       
        case CLEAR_RPM_ADMIN:
            return INITIAL_STATE;
        case CLEAR_RPM_PATIENT_VITALS:
            return {
                ...state,
                    vitalhistory:""
            };
        default:
            return state;
    }

};
