

import React, { useState } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { UPDATE_CONSULTATION_ADD_ALLERGY, UPDATE_CONSULTATION_EDIT_ALLERGY, UPDATE_CONSULTATION_REMOVE_ALLERGY } from '../../actions/type';
import './allergiescard.css'
import { Form, Select } from 'antd';
import { AddIcon, CloseIcon, SaveIcon } from '../../assets/Logos/Icons';
import CommonDeleteModalBody from '../../components/CommonDeleteModalBody/CommonDeleteModalBody'

function AllergiesCard({ addAllergy, allergies, setForm }) {

    const { Option } = Select;
    let dispatch = useDispatch()
    const [form] = Form.useForm();
    const [deleteModal, setDeleteModal] = useState(false)
    const [deleteIndex, setDeleteIndex] = useState(null)


    let [forms, setForms] = useState([
        {
            id: allergies&&allergies.length > 0 ? allergies.at(-1).id + 1 : 1,
            name: "",
            reaction: "",
            status: ""
        }
    ])


    let addForm = () => {

        setForms([
            ...forms,
            {
                id: forms.length,
                name: "",
                reaction: "",
                status: ""
            }
        ])

    }

    let removeForm = (index) => {

        // if (forms.length > 1) {
        //     setForms(forms.filter((element) => element.id != index))
        // }
        setDeleteIndex(index)
        setDeleteModal(true)

    }
    const handlePopupDeleteOk = () => {

      
        dispatch({
            type: UPDATE_CONSULTATION_REMOVE_ALLERGY,
            payload: { index:deleteIndex }
        })
        
        setForm(false)

    }



    let addElement = async () => {

        try {
            let res = await form.validateFields();

            console.log("Validation is successful", res);
            setForms([
                ...forms,
                {
                    id: forms.at(-1).id + 1,
                    name: "",
                    reaction: "",
                    status: ""
                }
            ])

            // Validation is successful
        } catch (errors) {
            console.log("Errors in the fields");
            // Errors in the fields
        }



    }

    let removeElement = (index) => {

        if (forms.length > 1) {
            setForms(forms.filter((element) => element.id != index))
        }

    }

    let saveElements = () => {
        addAllergy(forms)
        setForm(false)
    }

    let updateCard = ({ key, value, id }) => {

        let updatedForm = forms.map((each) => {
            if (each.id == id) {
                each[key] = value
            }
        })
        setForm(updatedForm)

    }

    let handleubmitForm = (data) => {

        addAllergy(forms)
        setForm(false)
    }
    

    return (
        <div className="allergy-form-container w-100">

            <Form
                form={form}
                name="basic"
                onFinish={handleubmitForm}
            // onFinishFailed={onFinishFailed}
            >


                {
                    forms && forms.length > 0 ?

                        forms.map((element, key) => {
                            return (
                                <div className="allergies-card-container">

                                    <Form.Item
                                        name={`name${key}`}
                                        rules={[{ required: true, message: 'Enter the name of allergen' }]}
                                    >
                                        <input
                                            placeholder='Enter Allergen'
                                            onChange={(e) => { updateCard({ key: "name", value: e.target.value, id: element.id }) }}
                                            key={element.id}
                                            type="text"
                                            className="allergies-card-input" />
                                    </Form.Item>



                                    <Form.Item

                                        name={`reaction${key}`}
                                        rules={[{ required: true, message: 'Let us know what kind of reaction does the allergen cause' }]}
                                    >
                                        <input
                                            onChange={(e) => { updateCard({ key: "reaction", value: e.target.value, id: element.id }) }}
                                            type="text" className="allergies-card-input" placeholder="Enter Reaction" />
                                    </Form.Item>

                                    <Form.Item
                                        name={`Status${key}`}
                                        rules={[{ required: true, message: 'Select status' }]}
                                    >
                                        <Select
                                            onChange={(e) => { updateCard({ key: "status", value: e, id: element.id }) }}
                                            placeholder="Current Status" style={{ width: "100%", fontSize: "14px" }}>
                                             <Option value="Present">Present</Option>
                                            <Option value="Not Active">Not Active</Option>
                                        </Select>
                                    </Form.Item>
                                    <div onClick={() => { removeForm(element.id) }}
                                        className="d-flex flex-row justify-content-start align-items-center"
                                        style={{ marginTop: ".8rem", cursor: "pointer" }}>
                                        {/* <CloseIcon /> */}
                                        <img src={require('../../assets/InterBranch/PNGIcons/delete.png')}   style={{width:"25px"}} alt="" />
                                        <span className="remove-text">Remove</span>
                                    </div>

                                </div>
                            )
                        })

                        : null
                }



                <div className='add-itrms-button-container'>
                    <button type='button' className="medicines-list-add-btn secondary-add-button "
                        onClick={addElement}
                    ><AddIcon primary /> Add more</button>
                    <button className="medicines-list-add-btn "
                    // onClick={saveElements}
                    >
                        <SaveIcon/>
                        Save</button>
                </div>
            </Form>
            <CommonDeleteModalBody
                onOk={handlePopupDeleteOk}
                className="consultation-delete-modal"
                state={deleteModal}
                setState={setDeleteModal} />

        </div>
    )
}

export default AllergiesCard
