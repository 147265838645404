import React from 'react'
import styles from './pageloader.module.scss'
import Lottie from "lottie-react";
import { AssetStore } from '../../assets/AssetsStore';

function PageLoader() {
    return (
        <div className={styles.container}>
            <Lottie
                style={{ width: "30%" }}
                animationData={AssetStore.pageloader}
                loop={false} />
        </div>
    )
}

export default PageLoader