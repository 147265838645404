import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useLocation, useRouteMatch, useHistory } from "react-router-dom";
import './common_delete_modal.css'

import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
// import { setModalRedux } from '../../Redux/Slice/ModalSlice';
// import { deleteFromReduxTable } from '../../Redux/Slice/TableDataSlice';

function CommonDeleteModalBody({state,setState, className, onOk}) {



    const handleOkClick = ()=>{

        if(onOk)
        {
            onOk()
        }
       
        setState(false)
    }

   

    return (

        <>
          
        <Modal show={state} 
        onHide={()=>{setState(false)}}
        className={className}
        backdrop="static"
        keyboard={false} centered>
          
          <div className={`custome-modal-container-center`}>

                            {/* <div className="clickable" onClick={closeModal}>
                                &nbsp;
                            </div> */}

                            <div className={`menu-container`}>
                            <div className="common-delete-modal-container">
                                <div className='delete-model-content'>

                                    <div className="icon-container">
                                        <img src={require('../../assets/InterBranch/PNGIcons/delete.png')} alt="" />
                                    </div>

                                    <div className="caption-container">

                                        <span> Are you sure you want to delete ?</span>

                                        {/* We can read the name from prop */}
                                        {/* <span> <u><b>{name} ?</b></u></span> */}


                                    </div>

                                    <div className="button-container">
                                        <button className='no-btn' onClick={()=>{setState(false)}}>NO</button>
                                        <button className='yes-btn' onClick={handleOkClick}>YES</button>
                                    </div>

                                </div>
                            </div>
                            </div>

                        </div>

          
        </Modal>
      </>
    );
}

export default CommonDeleteModalBody;
