

export let countryDatabase = [{"name":"India","flag":"🇮🇳","code":"IN","dial_code":"+91"}]

export const getCountryFlagFromCountryCode =(countryName)=>{

    if(countryName)
    {
       let countryData= countryDatabase.filter(element=>element.code.toUpperCase()==countryName.toUpperCase())

      if(countryData.length>0)
      {
            return countryData[0].flag
      }
      else{
        console.log("Country code not match in flag JSON");
        console.log("Country code==>",countryName);
        // console.log("Country code not match in flag JSON");
      }
    
    }

}

export const getCountryArray =()=>{

return countryDatabase

}

export const getCountryDetailsByCountryCode =async (countryName)=>{

  if(countryName)
  {
     let countryData= countryDatabase.filter(element=>element.code.toUpperCase()==countryName.toUpperCase() || element.dial_code.toUpperCase()==countryName.toUpperCase() )

    if(countryData.length>0)
    {
          return countryData[0]
    }
    else{
      console.log("Country code not match in flag JSON");
      console.log("Country code==>",countryName);
      // console.log("Country code not match in flag JSON");
    }
  
  }

}



