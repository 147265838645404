import {
  Add_DIAGNOSIS_PRESCRIPTION,
  Add_EXAMINATION_PRESCRIPTION,
  Add_INSTRUCTION_PRESCRIPTION,
  Add_INVESTIGATION_PRESCRIPTION,
  Add_MEDICAL_HISTORY_PRESCRIPTION,
  Add_MEDICATION_PRESCRIPTION,
  Add_NON_VACC_REASON_PRESCRIPTION,
  Add_SYMPTOM_PRESCRIPTION,
  LOAD_TEMPLATE_IN_MANAGE_PRECRIPTION,
  TOGGLE_SELECT_DIAGNOSIS_PRESCRIPTION,
  TOGGLE_SELECT_EXAMONATION_PRESCRIPTION,
  TOGGLE_SELECT_INSTRUCTION_PRESCRIPTION,
  TOGGLE_SELECT_INVESTIGATION_PRESCRIPTION,
  TOGGLE_SELECT_MEDICAL_HISTORY_PRESCRIPTION,
  TOGGLE_SELECT_MEDICATION_PRESCRIPTION,
  TOGGLE_SELECT_SYMPTOM_PRESCRIPTION,
  UPDATE_MANAGE_PRE_SUBMISSION_DATA,
} from "../actions/type";

const symptoms = [
  { name: "Fever" },
  { name: "Noisy respiration" },
  { name: "Cold" },
  { name: "Cough" },
  { name: "Vomiting" },
  { name: "Sore throat" },
  { name: "Covid-19 contact" },
  { name: "Loosing stools" },
  { name: "Rash" },
  { name: "Runny nose" },
  { name: "Corysa" },
];
const medicalHistory = [
  { name: "T2DM" },
  { name: "Hypertension" },
  { name: "Hyperthyroidism" },
  { name: "Hyperlipidemia" },
  { name: "High BP" },
  { name: "CKD" },
];
const examinations = [
  { name: "Tachypnea" },
  { name: "No fast breathing" },
  { name: "Active child" },
  { name: "Febile" },
  { name: "Well child" },
  { name: "No dehydration" },
];
const diagnosis = [
  { name: "Viral Fever" },
  { name: "Allergy Bronchitis" },
  { name: "Active febrile illness" },
  { name: "WALRI" },
  { name: "LRTI Lower respiration" },
];
const investigations = [
  { name: "Urine Routine Microscopic (R/M)" },
  { name: "CBC - Complete Blood Count Haemogram" },
  { name: "C Reactive Protein CRP" },
  { name: "Urine Routine" },
  { name: "Dengue Fever NS1" },
  { name: "Malaria Parasite" },
  { name: "X Ray Chest" },
  { name: "Covid RT- PCR" },
  { name: "Blood Culture & Sensitivity" },
];
const instructions = [
  { name: "Inform if fever more than 72 hours" },
  { name: "Plently of fluids" },
  {
    name: "Fluids are coconut water/ nimbu pani/ lassi/ Electral RTU/ Apple Juice",
  },
  { name: "Steam Inhalation" },
  { name: "If fever is >99; take Crocin/ Calpol" },
];
const medications = [
  { name: "Sus Calpol (250 mg)" },
  { name: "Syr Meftal P" },
  { name: "Syr Ondem" },
  { name: "Syr Emeset" },
  { name: "Syr Emeset (2 mg)" },
  { name: "Syr Zinconia" },
  { name: "Syr Zincolife" },
  { name: "Syr Z & D DS" },
];

let reasonForNonVaccination=["Reason-1", "Reason-2", "Reasoon-3"]

const INITIAL_STATE = {
  submissionData: {
    height_messurment: "cm",
    weight_messurment: "kg",
    gender: "Male",
    symptoms: [],
    medicalHistory: [],
    examinations: [],
    diagnosis: [],
    investigations: [],
    instructions: [],
    medications: [],
  },
  initialData: {
    symptoms: [...symptoms],
    medicalHistory: [...medicalHistory],
    examinations: [...examinations],
    diagnosis: [...diagnosis],
    investigations: [...investigations],
    instructions: [...instructions],
    medications: [...medications],
    reasonForNonVaccination:[...reasonForNonVaccination]
  },
};

export default (state = INITIAL_STATE, { type, payload }) => {
  switch (type) {
    case UPDATE_MANAGE_PRE_SUBMISSION_DATA:
      return {
        ...state,
        submissionData: { ...state.submissionData, ...payload },
      };

    case Add_SYMPTOM_PRESCRIPTION:
      return {
        ...state,
        initialData: { ...state.initialData, symptoms: payload },
      };

    case Add_MEDICAL_HISTORY_PRESCRIPTION:
      return {
        ...state,
        initialData: { ...state.initialData, medicalHistory: payload },
      };
    case Add_EXAMINATION_PRESCRIPTION:
      return {
        ...state,
        initialData: { ...state.initialData, examinations: payload },
      };
    case Add_DIAGNOSIS_PRESCRIPTION:
      return {
        ...state,
        initialData: { ...state.initialData, diagnosis: payload },
      };
    case Add_INVESTIGATION_PRESCRIPTION:
      return {
        ...state,
        initialData: { ...state.initialData, investigations: payload },
      };
    case Add_INSTRUCTION_PRESCRIPTION:
      return {
        ...state,
        initialData: { ...state.initialData, instructions: payload },
      };
    case Add_MEDICATION_PRESCRIPTION:
      return {
        ...state,
        initialData: { ...state.initialData, medications: payload },
      };

      case Add_NON_VACC_REASON_PRESCRIPTION:
        return {
          ...state,
          initialData: { ...state.initialData, reasonForNonVaccination: payload },
        };

    case TOGGLE_SELECT_SYMPTOM_PRESCRIPTION:
      return {
        ...state,
        submissionData: { ...state.submissionData, symptoms: payload },
      };
    case TOGGLE_SELECT_MEDICAL_HISTORY_PRESCRIPTION:
      return {
        ...state,
        submissionData: { ...state.submissionData, medicalHistory: payload },
      };
    case TOGGLE_SELECT_EXAMONATION_PRESCRIPTION:
      return {
        ...state,
        submissionData: { ...state.submissionData, examinations: payload },
      };
    case TOGGLE_SELECT_DIAGNOSIS_PRESCRIPTION:
      return {
        ...state,
        submissionData: { ...state.submissionData, diagnosis: payload },
      };
    case TOGGLE_SELECT_INVESTIGATION_PRESCRIPTION:
      return {
        ...state,
        submissionData: { ...state.submissionData, investigations: payload },
      };
    case TOGGLE_SELECT_INSTRUCTION_PRESCRIPTION:
      return {
        ...state,
        submissionData: { ...state.submissionData, instructions: payload },
      };
    case TOGGLE_SELECT_MEDICATION_PRESCRIPTION:
      return {
        ...state,
        submissionData: { ...state.submissionData, medications: payload },
      };

    case LOAD_TEMPLATE_IN_MANAGE_PRECRIPTION:
      return {
        ...state,
        submissionData: { ...payload },
      };
    default:
      return state;
  }
};
