import pageloader from "./AnimatedIcon/pageloader.json";
import searching from "./Animations/searching.json";
import noData from "./Animations/nodata.json";
import patientDetails from "./ManagePrescription/patientDetails.svg";
import arrow_up from "./ManagePrescription/arrow_up.svg";
import calander from "./ManagePrescription/calander.svg";
import incr from "./ManagePrescription/incr.svg";
import decr from "./ManagePrescription/decr.svg";
import search from "./ManagePrescription/search.svg";
import cnote from "./ManagePrescription/cnote.svg";
import diagnosis from "./ManagePrescription/diagnosis.svg";
import examination from "./ManagePrescription/examination.svg";
import med_history from "./ManagePrescription/med_history.svg";
import symptom from "./ManagePrescription/symptom.svg";
import treatment_advice from "./ManagePrescription/treatment_advice.svg";
import instructions from "./ManagePrescription/instructions.svg";
import medication from "./ManagePrescription/medication.svg";
import investigations from "./ManagePrescription/investigations.svg";
import followup from "./ManagePrescription/followup.svg";
import referal from "./ManagePrescription/referal.svg";
import print from "./ManagePrescription/print.svg";
export const AssetStore = {
  pageloader,
  searching,
  noData,
  managePrescription: {
    patientDetails,
    arrow_up,
    calander,
    incr,
    decr,
    search,
    cnote,
    diagnosis,
    examination,
    med_history,
    symptom,
    treatment_advice,
    instructions,
    medication,
    investigations,
    followup,
    referal,
    print,
  },
};
